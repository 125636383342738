@import '@/views/variables.scss';

.header {

  position: fixed;
  height: 84px;

  &_logo {
    height: 44px;
    svg {
      height: 100%;
    }
    img {
      height: 100%;
    }
  }

  &_dlogo {
    height: 24px;
    svg {
      height: 100%;
    }
    img {
      height: 100%;
    }
  }

}

.form {
  
  flex: 0 0 auto;
  z-index: 10;
  display: block;
  padding: spacer(xxl);
  background-color: rgba(0,0,0,0.3);
  border-radius: spacer(base);

  width: 480px;

  label {
    color: rgba(255,255,255,0.7);
    font-size: .875rem;
    padding-bottom: 4px;
  }  

}

